// @ts-nocheck due to the image imports
import React, { Component } from 'react'
import { imageDesktop, gridItem } from './HomePage.scss'
import Lottie from 'lottie-react'
import snowFlakes from 'assets/animations/snowFlakes.json'
import image1 from 'src/assets/images/home/383866_alt3.jpg'
import image2 from 'src/assets/images/home/383867_alt3.jpg'
import image3 from 'src/assets/images/home/384377_alt3.jpg'
import image4 from 'src/assets/images/home/752453_alt3_4x5.jpg'
import image5 from 'src/assets/images/home/752608_alt3.jpg'
import image6 from 'src/assets/images/home/753836_alt3_4x5.jpg'
import image7 from 'src/assets/images/home/754602_alt3.jpg'
import image8 from 'src/assets/images/home/754710_alt3_4x5.jpg'
import image9 from 'src/assets/images/home/755483_alt3_4x5.jpg'
import image10 from 'src/assets/images/home/755927_alt3.jpg'
import image11 from 'src/assets/images/home/BANKHOLIDAY_WW_SOCIAL_ONLY_WK18_1_RT.jpg'
import image12 from 'src/assets/images/home/BEAUTY_WK_21_3_RT.jpg'
import image13 from 'src/assets/images/home/BEAUTY_WK_21_8_RT1.jpg'
import image14 from 'src/assets/images/home/ESSENTIALS_KNITS__KW_SOCIAL_ONLY_WK4_0249.jpg'
import image15 from 'src/assets/images/home/FAST_TRACK_STILL_LIFE_0138_RGB_RT.jpg'
import image16 from 'src/assets/images/home/FAST_TRACK_STILL_LIFE_0151_RGB_RT.jpg'
import image17 from 'src/assets/images/home/FAST_TRACK_STILL_LIFE_0166_RGB_RT.jpg'
import image18 from 'src/assets/images/home/FAST_TRACK_STILL_LIFE_0458_RGB_RT.jpg'
import image19 from 'src/assets/images/home/FAST_TRACK_STILL_LIFE_0513_RGB_RT.jpg'
import image20 from 'src/assets/images/home/FAST_TRACK_STILL_LIFE_0771_WW_RGB_RT.jpg'
import image21 from 'src/assets/images/home/FAST_TRACK_STILL_LIFE_0803_WW_RGB_RT.jpg'
import image22 from 'src/assets/images/home/FAST_TRACK_STILL_LIFE_2506_RGB_RT.jpg'
import image23 from 'src/assets/images/home/FAST_TRACK_STILL_LIFE_PINK_WW_RGB_RT.jpg'
import image24 from 'src/assets/images/home/FRIDAY_WW_TRADE_REACTIVE_SOCIAL_BLOG_ONLY_WK24_1_RT.jpg'
import image25 from 'src/assets/images/home/FRIDAY_WW_TRADE_REACTIVE_SOCIAL_BLOG_ONLY_WK24_6_RT.jpg'
import image26 from 'src/assets/images/home/KW_AUTUMN_WINTER_JC_GP0418.jpg'
import image27 from 'src/assets/images/home/MW_FRIDAY_NEW_IN_SOCIAL_WEEK_4_RT.jpg'
import image28 from 'src/assets/images/home/RAMADAN_WW_SOCIAL_ONLY_WK11_1_3_RT.jpg'
import image29 from 'src/assets/images/home/RELAXED_OUTFIT_KW_SOCIAL_ONLY_WK21_1_RT.jpg'
import image30 from 'src/assets/images/home/RELAXED_OUTFIT_KW_SOCIAL_ONLY_WK21_2_RT.jpg'
import image31 from 'src/assets/images/home/SHORTS_MW_SOCIAL_ONLY_WK15_RT.jpg'
import image32 from 'src/assets/images/home/T-SHIRT_BLOG_REFRESH_MW_BLOG_SOCIAL_WEB_ONLY_WK18_3_RT.jpg'

const backgroundImages = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image30,
  image31,
  image32,
]

interface Props {
  xmasAnimate?: boolean
}

export default class BackgroundImage extends Component<Props> {
  render(): JSX.Element {
    const number = this.randomNumberBetween1And(backgroundImages.length)

    return this.props.xmasAnimate ? (
      <Lottie
        animationData={snowFlakes}
        loop={true}
        className={`${imageDesktop} ${gridItem}`}
        style={{
          backgroundImage: `url(${backgroundImages[number]})`,
        }}
      />
    ) : (
      <div
        className={`${imageDesktop} ${gridItem}`}
        style={{
          backgroundImage: `url(${backgroundImages[number]})`,
        }}
      />
    )
  }

  randomNumberBetween1And = (number: number): void => Math.floor(Math.random() * number)
}
