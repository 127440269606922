import Lottie from 'lottie-react'
import React from 'react'
import snowFlakes from 'assets/animations/snowFlakes.json'
import { xmasSnowFlakesContainer, snowFlake, xmasImage } from './XmasSnowFlakes.scss'

export const XmasSnowFlakes = (): JSX.Element => (
  <div className={xmasSnowFlakesContainer}>
    <div
      className={xmasImage}
      style={{
        backgroundImage: `url(${require('../../../assets/images/presents.png')})`,
      }}
    />
    <Lottie animationData={snowFlakes} loop={true} className={snowFlake} />
    <Lottie animationData={snowFlakes} loop={true} className={snowFlake} />
    <Lottie animationData={snowFlakes} loop={true} className={snowFlake} />
    <div
      className={xmasImage}
      style={{
        backgroundImage: `url(${require('../../../assets/images/santa-claus.png')})`,
      }}
    />
  </div>
)
