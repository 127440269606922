import React from 'react'
import PrimaryNavigation from '../PrimaryNavigation'
import UtilityNavigation from '../UtilityNavigation'
import LockStatus from '../LockStatus'
import { header, logoContainer } from './Header.scss'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import XmasLights from 'src/components/Xmas/XmasLights/XmasLights'
import { XmasSnowFlakes } from 'src/components/Xmas/XmasSnowFlakes/XmasSnowFlakes'
import { RIChristmas } from 'assets/icons/RIChristmas'

interface Props {
  location: Location
}

export class Header extends React.Component<Props> {
  render(): JSX.Element {
    return (
      <>
        <header id="NavBar" className={header}>
          <div className={logoContainer}>
            <Link to="/">
              <RIChristmas />
            </Link>
          </div>
          <PrimaryNavigation mobileMenuOpen={false} />
          <LockStatus />
          <UtilityNavigation />
        </header>
        <XmasLights />
        {this.props?.location?.pathname !== '/' && <XmasSnowFlakes />}
      </>
    )
  }
}

// @ts-ignore
export default withRouter(Header)
